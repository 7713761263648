const adjustHelpBubble = () => {
  const helpBubbles = document.querySelectorAll("span.help_bubble");

  helpBubbles.forEach((helpBubble) => {
    helpBubble.addEventListener("mouseover", adjustOverlayPosition);
  });

  function adjustOverlayPosition() {
    const overlay = this.querySelector(".overlay_element");
    const arrow = this.querySelector(".overlay_element > div:first-child");
    const textElement = this.querySelector(".text_element");
    const boundingRect = this.getBoundingClientRect();
    const overlayWidth = 300;

    const scrollableParent = getScrollableParent(this);
    const scrollableParentRect = scrollableParent.getBoundingClientRect();
    const parentLeftBoundary = scrollableParentRect.left;
    const parentRightBoundary = scrollableParentRect.right;

    const desiredOffset = textElement.offsetWidth / 2 - overlayWidth / 2;
    const minOffset = -1 * (boundingRect.left - parentLeftBoundary);
    const maxOffset = parentRightBoundary - boundingRect.left - overlayWidth;

    const leftOffset = Math.max(Math.min(desiredOffset, maxOffset), minOffset);

    const adjustment = leftOffset - desiredOffset;

    const arrowLeftOffset = overlayWidth / 2 - 15 - adjustment;

    overlay.style.width = `${overlayWidth}px`;
    overlay.style.left = `${leftOffset}px`;
    arrow.style.left = `${arrowLeftOffset}px`;
  }

  function getScrollableParent(element) {
    let currentElement = element;

    while (currentElement.parentElement) {
      currentElement = currentElement.parentElement;

      if (
        getComputedStyle(currentElement).overflowX === "auto" ||
        getComputedStyle(currentElement).overflowX === "scroll"
      ) {
        return currentElement;
      }
    }

    return document.documentElement;
  }
};

export default adjustHelpBubble;
